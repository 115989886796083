import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { JsonBlock } from '@ui-kit/JsonBlock';
import { ErrorBlock } from '@ui-kit/ErrorBlock';
import { useAuthToken } from '@/apollo';
import { Button } from '@/components/ui/button';
import { PauseCircleIcon, PlayCircleIcon } from 'lucide-react';
import { InfoAlert } from '@/components/ui/alert';
import { Input } from '@/components/ui/input';
import { Loader } from '@utils';
import { WarningIcon } from '@ui-kit/Icons';
import { CodeSample } from '@/components/ui/code-sample';
import { GetScriptHistoryState } from '@gql';
import { gql } from '@apollo/client';
import { WEBSOCKET_SAMPLE_WITH_SDK, WEBSOCKET_SAMPLE_WITHOUT_SDK, POLLING_SAMPLE } from '@/constants/code-sample';

interface WsMsg {
  type: 'error' | 'message';
  key: string;
  at: string;
  id: string;
  block: string;
  blockHash: string;
  network: string;
  channel?: string;
  data: any;
}

export function RealtimeList({ id, draft, hasUnsavedChanges }: { id: ScriptId; draft: boolean; hasUnsavedChanges?: boolean; }) {
  const [messages, setMessageHistory] = useState<WsMsg[]>([]);
  const [paused, setPaused] = useState(false);
  const [msgError, setMsgError] = useState(null);
  const [errError, setErrError] = useState(null);
  const [channel, setChannel] = useState<string>('');
  const token = useAuthToken();

  useEffect(() => setMessageHistory([]), [draft]);

  const storeHistory = Loader.query<GetScriptHistoryState>(
    gql`
      query GetScriptHistoryState($id: ScriptId!) {
        script(id: $id) {
          storeHistory
        }
      }
    `,
    {
      variables: { id },
    },
  ).map(x => x.script.storeHistory);

  const isKeepHistoryEnabled = storeHistory.match.notOk(() => null).ok(history => !!history);

  Loader.useWrap({ id, token, paused, draft, channel } as const)
    .debounce(1000, true)
    .map(x => x.paused ? Loader.skipped : x)
    .onOk(({ id, token, paused, draft, channel }) => {
      if (paused) {
        return;
      }
      setMessageHistory([]);
      console.log('connecting to ws server');
      // connect to messages
      const msgSocket = io(import.meta.env.VITE_WS_SERVER!, {
        query: {
          stream: draft ? id + '-draft' : id,
          token,
          includeMetadata: true,
          // set channel only if not undefined
          ...(channel ? { channel } : {}),
          // replay: '10mins',
        },
        transports: ['websocket'],
      });
      msgSocket.on('message', msg => {
        setMsgError(null);
        setMessageHistory(prev => {
          const key = msg.channel ? `${msg.id} on ${msg.channel}` : msg.id;
          if (prev.some(x => x.key === key)) {
            console.warn('duplicate message received', key);
            return prev;
          }

          return [
            {
              type: 'message',
              at: new Date().toISOString(),
              key,
              id: msg.id,
              block: msg.block,
              blockHash: msg.blockHash,
              network: msg.network,
              data: msg.data,
              channel: msg.channel,
            },
            ...prev.slice(0, 10)]
        });
      });
      msgSocket.on('error', err => {
        console.error('WS error on message stream', err);
        setMsgError(err);
      });
      msgSocket.connect();

      // connect to errors
      const errSocket = io(import.meta.env.VITE_WS_SERVER!, {
        query: {
          errorsStream: draft ? id + '-draft' : id,
          token,
          includeMetadata: true,
        },
        transports: ['websocket'],
      });
      errSocket.on('message', msg => {
        setErrError(null);
        setMessageHistory(prev => {
          const key = `error ${msg.id}`;
          if (prev.some(x => x.key === key)) {
            console.warn('duplicate error received', key);
            return prev;
          }
          return [
            {
              type: 'error',
              at: new Date().toISOString(),
              key,
              id: msg.id,
              block: msg.block,
              blockHash: msg.blockHash,
              network: msg.network,
              data: msg.data,
            },
            ...prev.slice(0, 10)]
        });
      });
      errSocket.on('error', err => {
        console.error('WS error on error stream', err);
        setErrError(err);
      });
      errSocket.connect();
      return () => {
        console.log('disconnecting from ws server');
        msgSocket.disconnect();
        errSocket.disconnect();
      };
    });

  return (
    <div className="flex flex-col">
      <div className="py-4">
        <Input
          className="h-10 bg-secondary"
          placeholder="Channel"
          value={channel}
          onChange={e => setChannel(e.target.value)}
        />
      </div>
      {draft && hasUnsavedChanges && <div className="py-2 flex gap-2 items-center text-warning">
        <WarningIcon />
        Realtime simulation runs on the last saved version of your draft
      </div>}
      <div className="py-4">
        <Button className="w-full flex gap-2" size="lg" variant="secondary" onClick={() => setPaused(!paused)}>
          {paused ? <PlayCircleIcon className="w-4 h-4" /> : <PauseCircleIcon className="w-4 h-4" />}
          <span>{paused ? 'Resume streaming' : 'Pause streaming'}</span>
        </Button>
      </div>
      {/* TODO: not yet in design */}
      {!msgError && messages.length === 0 && <InfoAlert message='No message yet' />}
      {msgError && <div className="text-center m-5 italic text-red-700">Messages stream: {msgError}</div>}
      {errError && <div className="text-center m-5 italic text-red-700">Errors stream: {errError}</div>}
      <div className="flex flex-col gap-2">
        {messages.map((x) => (
          <div key={x.key}>
            {x.type === 'message' ? (
              <JsonBlock json={x.data} network={x.network} title={'#' + x.block} channel={x.channel} time={x.at} />
            ) : (
              <ErrorBlock network={x.network} title={'#' + x.block} error={x.data} time={x.at} />
            )}
          </div>
        ))}
      </div>
      <CodeSample 
        title="Connect using websockets" 
        docUrl="https://docs.bloomr.stream/DataStreaming/websocket" 
        sampleWithSdk={WEBSOCKET_SAMPLE_WITH_SDK}
        sampleWithoutSdk={WEBSOCKET_SAMPLE_WITHOUT_SDK}
        className="my-4"
        scriptId={id}
      />
      <CodeSample 
        title="Realtime reliable polling" 
        docUrl="https://docs.bloomr.stream/DataStreaming/polling" 
        sampleWithSdk={POLLING_SAMPLE}
        scriptId={id}
        warning={!isKeepHistoryEnabled 
          ? '"Keep history" must be enabled for this sample to work' 
          : undefined
        }
      />
    </div>
  );
}
