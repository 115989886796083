import { useState } from 'react';
import { Loader } from '../../../utils/loader';
import { gql, useApolloClient } from '@apollo/client';
import type {
  LoadScriptErrors,
  LoadScriptErrorsVariables,
  LoadScriptErrors_scriptErrors,
  LoadScriptHistory,
  LoadScriptHistoryVariables,
  LoadScriptHistory_scriptMessages,
  LoadWebhookErrors,
  LoadWebhookErrors_webhookErrors,
} from '@gql';
import { Button } from '@/components/ui/button';
import { delay, last, useTimeAgo } from '@utils';
import { JsonBlock } from '@ui-kit/JsonBlock';
import { ErrorBlock } from '@ui-kit/ErrorBlock';
import { PlayCircleIcon, SendIcon, TestTube2Icon, Trash2Icon } from 'lucide-react';
import { Pagination, PaginationContent, PaginationItem, PaginationNext } from '@/components/ui/pagination';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '@/components/ui/alert-dialog';
import { useNavigate } from 'react-router-dom';
import { ScriptDetailsTab } from './ScriptDetailsRoot';
import { Skeleton } from '@/components/ui/skeleton';
import { ErrorAlert, InfoAlert } from '@/components/ui/alert';
import { toast } from 'sonner';
import { CodeIcon } from '@radix-ui/react-icons';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

const MAX_CNT = 10;

export function HistoryList({ id }: { id: ScriptId }) {
  const [cursor, setCursor] = useState<string | null>(null);

  const historyLoader = Loader.query<LoadScriptHistory>(
    gql`
      query LoadScriptHistory($id: ScriptId!, $cursor: String, $take: Int!) {
        scriptMessages(script: $id, take: $take, cursor: $cursor, newestFirst: true) {
          id
          blockNumber
          blockHash
          networkId
          network
          data
          at
          hookStatus {hook processed error}
        }
      }
    `,
    {
      variables: { id, cursor, take: MAX_CNT } satisfies LoadScriptHistoryVariables,
    },
  ).map(x => x.scriptMessages);

  return (
    <div>
      {historyLoader.match
        .loadingOrSkipped(() => (
          <div className="flex flex-col gap-2">
            {Array(5).fill(null).map((_, i) => (
              <Skeleton key={i} className="h-40" />
            ))}
          </div>
        ))
        .error((e) => <ErrorAlert name={e.name} message={e.message} />)
        .ok(hist => (
          <div className="flex flex-col gap-2">
            {hist.map(x => (
              <HistoryLine key={x.id} scriptId={id} line={x} />
            ))}

            {hist.length === 0 && <InfoAlert message='No stored history' />}

            <Pagination className="py-5">
              <PaginationContent>
                <PaginationItem data-state={hist.length < MAX_CNT && 'disabled'} className="cursor-pointer" onClick={() => setCursor(last(hist)?.id!)}>
                  <PaginationNext />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        ))}
    </div>
  );
}

function HistoryLine({ line, scriptId }: { scriptId: ScriptId; line: LoadScriptHistory_scriptMessages }) {
  const apollo = useApolloClient();
  const navigateTo = useNavigate();

  const replay = async () => {
    toast.promise(apollo.mutate({
      mutation: gql`mutation ReplayHistoryLine($script: ScriptId!, $msg: String!) {
        replayMessage(scriptId: $script, messageId: $msg)
      }`,
      variables: { script: scriptId, msg: line.id },
    }), {
      loading: 'Replaying...',
      success: 'Replayed successfully',
      error: 'Failed to replay message',
    });
  }

  const handleRunTest = () => {
    navigateTo(`/scripts/${scriptId}?tab=${ScriptDetailsTab.Code}`, { state: { data: line } })
  }

  return <JsonBlock
    json={line.data}
    network={line.network}
    title={<div>
      #{line.blockNumber?.toString()}&nbsp;
      <HookStatus msg={line} status={line.hookStatus} />
    </div>}
    time={line.at}
    actions={
      <>
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger>
              <Button size="icon" variant="secondary" onClick={handleRunTest}>
                <TestTube2Icon className="w-4 h-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent className="bg-tooltip">
              Run test
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <AlertDialog>
          <AlertDialogTrigger>
            <TooltipProvider delayDuration={100}>
              <Tooltip>
                <TooltipTrigger>
                  <Button size="icon" variant="secondary">
                    <SendIcon className="w-4 h-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent className="bg-tooltip">
                  Re-send message
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Re-send confirmation</AlertDialogTitle>
              <AlertDialogDescription>
                Re-send this message to your webhooks/websockets?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={replay}>Confirm</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    } />;
}

export function ExecErrorsList({ id }: { id: ScriptId }) {
  const [cursor, setCursor] = useState<string | null>(null);
  const [cnt, setCnt] = useState(0);

  const historyLoader = Loader.query<LoadScriptErrors>(
    gql`
      query LoadScriptErrors($id: ScriptId!, $cursor: String, $take: Int!) {
        scriptErrors(script: $id, take: $take, cursor: $cursor, newestFirst: true) {
          id
          blockNumber
          blockHash
          networkId
          network
          errors
          at
        }
      }
    `,
    {
      variables: { id, cursor, take: MAX_CNT } satisfies LoadScriptErrorsVariables,
      refetchWhenChanges: [cnt],
    },
  ).map(x => x.scriptErrors)
    .noFlickering();

  return (
    <div>
      {historyLoader.match
        .loadingOrSkipped(() => (
          <div className="flex flex-col gap-2">
            {Array(5).fill(null).map((_, i) => (
              <Skeleton key={i} className="h-20" />
            ))}
          </div>
        ))
        .error((e) => <ErrorAlert name={e.name} message={e.message} />)
        .ok(hist => (
          <div className="flex flex-col gap-2">
            {hist.map(x => (
              <ScriptErrorLine key={x.id} onReload={() => setCnt(x => x + 1)} scriptId={id} line={x} />
            ))}

            {hist.length === 0 && <InfoAlert message='No errors' />}

            <Pagination className="py-5">
              <PaginationContent>
                <PaginationItem data-state={hist.length < MAX_CNT && 'disabled'} className="cursor-pointer" onClick={() => setCursor(last(hist)?.id!)}>
                  <PaginationNext />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        ))}
    </div>
  );
}


export function WebhookErrorsList({ id }: { id: ScriptId }) {
  const [cursor, setCursor] = useState<string | null>(null);
  const [cnt, setCnt] = useState(0);

  const historyLoader = Loader.query<LoadWebhookErrors>(
    gql`
      query LoadWebhookErrors($id: ScriptId!, $cursor: String, $take: Int!) {
        webhookErrors(script: $id, take: $take, cursor: $cursor, newestFirst: true) {
          id
          blockNumber
          blockHash
          networkId
          network
          error
          data
          at
          fixed
          hook
        }
      }
    `,
    {
      variables: { id, cursor, take: MAX_CNT } satisfies LoadScriptErrorsVariables,
      refetchWhenChanges: [cnt],
    },
  ).map(x => x.webhookErrors)
    .noFlickering();

  return (
    <div>
      {historyLoader.match
        .loadingOrSkipped(() => (
          <div className="flex flex-col gap-2">
            {Array(5).fill(null).map((_, i) => (
              <Skeleton key={i} className="h-20" />
            ))}
          </div>
        ))
        .error((e) => <ErrorAlert name={e.name} message={e.message} />)
        .ok(hist => (
          <div className="flex flex-col gap-2">
            {hist.map(x => (
              <WebhookErrorLine key={x.id} onReload={() => setCnt(x => x + 1)} scriptId={id} line={x} />
            ))}

            {hist.length === 0 && <InfoAlert message='No errors' />}

            <Pagination className="py-5">
              <PaginationContent>
                <PaginationItem data-state={hist.length < MAX_CNT && 'disabled'} className="cursor-pointer" onClick={() => setCursor(last(hist)?.id!)}>
                  <PaginationNext />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        ))}
    </div>
  );
}

function ScriptErrorLine({ line, scriptId, onReload }: { scriptId: ScriptId; onReload: () => void; line: LoadScriptErrors_scriptErrors }) {
  const apollo = useApolloClient();
  const navigateTo = useNavigate();

  const wrapReload = async (pause: boolean, promise: Promise<any>) => {
    await promise;
    if (pause) {
      await delay(1000);
    }
    onReload();
  }

  const replay = async () => {
    toast.promise(wrapReload(true, apollo.mutate({
      mutation: gql`mutation ReplayErrorLine($script: ScriptId!, $id: String!) {
        replayError(scriptId: $script, errorId: $id)
      }`,
      variables: { script: scriptId, id: line.id },
    })), {
      loading: 'Retrying...',
      success: 'Retry successfully sent',
      error: 'Failed to retry block',
    });
  }

  const deleteErr = async () => {
    toast.promise(wrapReload(false, apollo.mutate({
      mutation: gql`mutation DeleteErrorLine($script: ScriptId!, $id: String!) {
        deleteError(scriptId: $script, errorId: $id)
      }`,
      variables: { script: scriptId, id: line.id },
    })), {
      loading: 'Deleting...',
      success: 'Deleted successfully',
      error: 'Failed to delete error',
    });
  }

  const handleRunTest = () => {
    navigateTo(`/scripts/${scriptId}?tab=${ScriptDetailsTab.Code}`, { state: { data: line } })
  }

  const errTxts = line.errors.map(e => {
    if (typeof e === 'string') {
      return e;
    } else if ('error' in e && typeof e.error === 'string' && Object.keys(e).length === 1) {
      return e.error;
    } else {
      return JSON.stringify(e, null, '  ');
    }
  })
  return <ErrorBlock
    error={errTxts}
    network={line.network}
    title={'#' + line.blockNumber}
    time={line.at}
    actions={
      <>
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger>
              <Button size="icon" variant="secondary" onClick={handleRunTest}>
                <TestTube2Icon className="w-4 h-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent className="bg-tooltip">
              Run test
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <AlertDialog>
          <AlertDialogTrigger>
            <TooltipProvider delayDuration={100}>
              <Tooltip>
                <TooltipTrigger>
                  <Button size="icon" variant="secondary">
                    <PlayCircleIcon className="w-4 h-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent className="bg-tooltip">
                  Replay
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Re-send confirmation</AlertDialogTitle>
              <AlertDialogDescription>
                Re-send this message to your webhooks/websockets?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={replay}>Confirm</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        <AlertDialog>
          <AlertDialogTrigger>
            <Button size="icon" variant="secondary">
              <Trash2Icon className="w-4 h-4" />
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Delete confirmation</AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to delete this error?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={deleteErr}>Confirm</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    }
  />;
}


function copyCurlCommand(line: LoadWebhookErrors_webhookErrors | LoadScriptHistory_scriptMessages, url: string) {
  navigator.clipboard.writeText(
    `curl -X POST ${url} -H "Content-Type: application/json" -H 'id: ${line.id}' -H 'network: ${line.network}' -H 'network-id: ${line.networkId}' -H 'block-number: ${line.blockNumber}' -H 'block-hash: ${line.blockNumber}' -d '${JSON.stringify(line.data).replace(/'/g, "\\'")}'`
  );
  toast.info('curl command copied to clipboard');
}



function WebhookErrorLine({ line, scriptId, onReload }: { scriptId: ScriptId; onReload: () => void; line: LoadWebhookErrors_webhookErrors }) {

  return <JsonBlock
    json={line.data}
    network={line.network}
    actions={
      <TooltipProvider delayDuration={100}>
        <Tooltip>
          <TooltipTrigger>
            <Button size="icon" variant="secondary" onClick={() => copyCurlCommand(line, line.hook)}>
              <CodeIcon />
            </Button>
          </TooltipTrigger>
          <TooltipContent className="bg-tooltip">
            Copy curl command
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    }
    title={<>
      #{line.blockNumber}
      &nbsp;
      {line.fixed ? <span className="text-xs text-green-500"> ✅ {line.error} (retry success)</span> : <span className='text-xs text-red-500'>❌ {line.error}</span>}
    </>}
    time={line.at}
  />;
}


export function HookStatus({ msg, status }: { msg: LoadScriptHistory_scriptMessages; status: LoadScriptHistory_scriptMessages['hookStatus'] }) {
  const hasError = status.some(x => x.error);
  const hasNotProcessed = status.some(x => !x.processed);

  let icon: string;
  if (hasError) {
    icon = '❌';
  } else if (hasNotProcessed) {
    icon = '⏳';
  } else if (status.length) {
    icon = '✅';
  } else {
    return;
  }

  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger>{icon}</TooltipTrigger>
        <TooltipContent className="bg-tooltip">
          {status.map((x) => <HookLine key={x.hook} msg={msg} status={x} />)}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

function HookLine({ msg, status }: { msg: LoadScriptHistory_scriptMessages; status: LoadScriptHistory_scriptMessages['hookStatus'][0] }) {
  const ago = useTimeAgo(status.processed);
  return (
    <div className='whitespace-nowrap flex gap-2 items-center'>
      <span className='text-xs'>{status.hook} : </span>
      {status.error ? `❌` : status.processed ? `✅ ${ago}` : `⏳`}

      <TooltipProvider delayDuration={100}>
        <Tooltip>
          <TooltipTrigger>
            <Button size="icon" variant="secondary" onClick={() => copyCurlCommand(msg, status.hook)}>
              <CodeIcon />
            </Button>
          </TooltipTrigger>
          <TooltipContent className="bg-tooltip">
            Copy curl command
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>)
}
